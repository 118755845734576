import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Line = _resolveComponent("Line")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                style: {"width":"500px"},
                class: "bg-grey-darken-4 pa-12",
                rounded: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openGarage())),
                        "append-icon": "fas fa-warehouse",
                        color: "success",
                        size: "large",
                        variant: "elevated",
                        block: ""
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Open Garage ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_sheet, {
                style: {"width":"500px"},
                class: "bg-grey-darken-4 pa-12",
                rounded: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
                    default: _withCtx(() => [
                      _cache[8] || (_cache[8] = _createElementVNode("label", null, "Sensors:", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Sensors, (sensor) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: sensor.ID
                        }, [
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Sensor Value: " + _toDisplayString(sensor.SensorValue) + " ", 1),
                          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Sensor Location: " + _toDisplayString(sensor.SensorLocation) + " ", 1),
                          _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Sensor Type: " + _toDisplayString(sensor.SensorType) + " ", 1),
                          _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(" Recorded Time: " + _toDisplayString(sensor.CreatedAt) + " ", 1),
                          _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                          _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_sheet, {
        class: "bg-grey-darken-4 pa-12",
        rounded: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, { class: "mx-auto px-6 py-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_Line, {
                data: _ctx.chartJSGraph,
                options: _ctx.chartJSOptions
              }, null, 8, ["data", "options"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}